import React from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import classes from "./MainMenu.module.css";

const MainMenu = () => (
  <div className={classes.mainMenu}>
    <Container>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        bg="white"
        className={
          "p-0 font-weight-bold d-flex justify-content-center align-items-center"
        }
      >
        <Navbar.Brand href={process.env.REACT_APP_HOME_LINK}>
          <img
            src={process.env.REACT_APP_IHG_CORP_LOGO}
            height={"90px"}
            title={"International Hotel Group"}
            alt={"IHG Official Logo"}
          />
        </Navbar.Brand>
      </Navbar>
    </Container>
  </div>
);

export default MainMenu;
