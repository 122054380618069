import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

const FirstCallToAction = () => (
  <>
    <Container className="first-call-container">
      <Row>
        <Col>
          <p>
            {" "}
            Small or simple events are less than 50 attendees or 50 guest rooms
            on peak night.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1 className="instant-book-table-header">
            Instant Book in 5 Easy Steps:
          </h1>
        </Col>
      </Row>
      <Row className={"mx-0 mb-0 pb-3 number-row"}>
        <Col className="progression-items">
          <Row className="justify-content-center">
            <img
              src={process.env.REACT_APP_GLOBE_ICON}
              width={"58px"}
              height={"58px"}
              title={"IHG Globe Icon"}
              alt={"IHG Globe Icon"}
            />
          </Row>
        </Col>
        <Col className="progression-items">
          <Row className="justify-content-center">
            <img
              src={process.env.REACT_APP_BED_ICON}
              width={"58px"}
              height={"58px"}
              title={"IHG Bed Icon"}
              alt={"IHG Bed Icon"}
            />
          </Row>
        </Col>
        <Col className="progression-items">
          <Row className="justify-content-center">
            <img
              src={process.env.REACT_APP_TABLE_ICON}
              width={"58px"}
              height={"58px"}
              title={"IHG Table Icon"}
              alt={"IHG Table Icon"}
            />{" "}
          </Row>
        </Col>
        <Col className="progression-items">
          <Row className="justify-content-center">
            <img
              src={process.env.REACT_APP_GEAR_ICON}
              width={"58px"}
              height={"58px"}
              title={"IHG Gear Icon"}
              alt={"IHG Gear Icon"}
            />{" "}
          </Row>
        </Col>
        <Col className="progression-items">
          <Row className="justify-content-center">
            <img
              src={process.env.REACT_APP_HANDSHAKE_ICON}
              width={"58px"}
              height={"58px"}
              title={"IHG Handshake Icon"}
              alt={"IHG Handshake Icon"}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="text-md-center">
            Venues available in select cities across the U.S.
          </p>
        </Col>
        <Col>
          <p className="text-md-center">
            Real-time availability and assortment of room types.
          </p>
        </Col>
        <Col>
          <p className="text-md-center">
            Real-time availability of space, setup, food & beverage, and audio
            visual.
          </p>
        </Col>
        <Col>
          <p className="text-md-center">
            Agree to terms & conditions and provide event payment.
          </p>
        </Col>
        <Col>
          <p className="text-md-center">
            Receive direct book confirmation summary.
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default FirstCallToAction;
