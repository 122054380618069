import React from "react";
import moment from "moment";
import classes from "./DatepickerInput.module.css";

interface DatepickerInputProps {
  startDate: Date;
  endDate: Date | null;
  onClick?: () => void;
  onChange?: () => void;
  onKeyDown?: () => void;
}
const DatepickerInput = (props: DatepickerInputProps) => {
  const { startDate, endDate, onClick, onChange } = props,
    datepickerRef = React.useRef(null),
    rangeStart =
      startDate && moment(startDate).isValid()
        ? moment(startDate).format("MMM DD, YYYY")
        : null,
    rangeEnds =
      endDate && moment(endDate).isValid()
        ? moment(endDate).format("MMM DD, YYYY")
        : null,
    currentRange =
      !startDate && !endDate
        ? "Select your dates"
        : moment(startDate).isSame(endDate) || !endDate
        ? `${rangeStart}`
        : `${rangeStart} - ${rangeEnds}`;

  return (
    <input
      ref={datepickerRef}
      readOnly={true}
      value={currentRange}
      className={classes.datepickerInput + " hoverPrimary"}
      onClick={onClick}
      onChange={onChange}
    />
  );
};

export default DatepickerInput;
