import React from "react";
//import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Select from "react-select";

interface GooglePlaceInputProps {
  destSelection: any;
  updateValue: Function;
  options: any;
  setCurrValue: any;
  updateDestSelection: Function;
}
const GooglePlaceInput: React.FC<GooglePlaceInputProps> = (props) => {
  const { destSelection, updateValue } = props;

  const customStyles = {
    height: 44,
    minHeight: 44,

    menu: (provided: object) => ({
      ...provided,
      width: "20rem",
      marginTop: "-3rem",
    }),

    input: (provided: object) => ({
      ...provided,
      fontSize: "1.2rem",
    }),
    container: (provided: object) => ({
      width: "20rem",
    }),
    placeholder: (provided: object) => {
      return {
        ...provided,
        fontSize: "1.2rem",
      };
    },
    valueContainer: (provided: object) => ({
      ...provided,
      height: "44px",
    }),
    indicatorsContainer: (provided: object) => ({
      ...provided,
      height: "44px",
    }),
    option: (provided: object, state: any) => ({
      ...provided,
      fontSize: "1.2rem",
      backgroundColor:
        state.isFocused || state.isSelected ? "rgba(232, 84, 44, .3)" : "white",
      color: "#495057",
      "&:active": { backgroundColor: "rgba(232, 84, 44, .3)" },
    }),
    singleValue: (provided: object) => ({
      ...provided,
      fontSize: "1.2rem",
    }),
    control: (provided: object, state: any) => ({
      ...provided,
      boxShadow:
        state.isFocused || state.isSelected
          ? "0 0 0 0.2rem rgba(232, 84, 44, .25)"
          : "none",
      border:
        state.isFocused || state.isSelected
          ? "1px solid #E8542C"
          : "1px solid #ccc",
      "&:hover": {
        border: "1px solid rgba(232, 84, 44, 1)",
        outline: 0,
        boxShadow: "0 0 0 0.2rem rgba(232, 84, 44, .2)",
      },
    }),
  };

  const handleOnChange = (e: any) => {
    let tableValue =
      e[e.length - 1]?.value === null ? "" : e[e.length - 1]?.value;

    props.updateDestSelection(tableValue);
    updateValue(tableValue);
  };

  let innerRef: any = React.createRef();

  return (
    <div>
      <Select
        noOptionsMessage={() => "Sorry no other destinations available."}
        className="destinationSelect"
        id="destinationSelectId"
        options={props.options}
        value={
          props.options.find((x: any) => destSelection[0]?.includes(x.value))
            ? props.options.find((x: any) =>
                destSelection[0]?.includes(x.value)
              )
            : null
        }
        styles={{ ...customStyles }}
        ref={innerRef}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        selectProps={{
          styles: { ...customStyles },
          hideSelectedOptions: true,
        }}
        isMulti
        isSearchable
        autofocus
        hideSelectedOptions
        menuPlacement={"bottom"}
      ></Select>
      {/* <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
        apiOptions={{
          language: "en",
          region: "us",
        }}
        selectProps={{
          isClearable: true,
          placeholder: "Select from table below",
          value: searchValue,
          //inputValue: {searchValue ? searchValue : null},
          onChange: updateValue,
          styles: { ...customStyles },
          className: "destinationSelect",
          id: "destinationSelectId",
        }}
        minLengthAutocomplete={2}
        onLoadFailed={(error) =>
          console.error(
            "Could not inject Google script for location autocompletion",
            error
          )
        }
      /> */}
    </div>
  );
};

export default GooglePlaceInput;
