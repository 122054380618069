import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";

const SecondCallToAction = () => {
  return (
    <Container className={"py-5 px-0 company-info-container"}>
      <Row className={"company-info-row mb-5 py-5"}>
        <Col xs={8} className={"py-5 ml-1 company-info-col"}>
          <h3>Company Info </h3>
        </Col>

        <Col xs={4}>
          <a
            target="_blank"
            rel="noreferrer"
            href={process.env.REACT_APP_TRAVEL_LINK}
          >
            <span>Travel Advisory</span>
          </a>

          <br></br>

          <a
            target="_blank"
            rel="noreferrer"
            href={process.env.REACT_APP_AD_CHOICES_LINK}
          >
            <span>Ad Choices</span>
          </a>

          <br></br>
        </Col>
        <Col xs={4}>
          <a
            target="_blank"
            rel="noreferrer"
            href={process.env.REACT_APP_PRIVACY_COOKIE_LINK}
          >
            <span>Privacy Statement and Cookie Usage</span>
          </a>
          <br></br>

          <span>
            <a
              target="_blank"
              rel="noreferrer"
              href={process.env.REACT_APP_NO_SELL_INFO_LINK}
            >
              <span>Do Not Sell My Personal Information</span>
            </a>
          </span>
          <br></br>
        </Col>
      </Row>

      <Row className={"mt-5 d-flex justify-content-center"}>
        <img
          src={process.env.REACT_APP_IHG_BRAND_CHART}
          width={1200}
          title={"IHG Hotels and Resort Images"}
          alt={"IHG Hotels and Resort Images"}
        />
      </Row>
    </Container>
  );
};

export default SecondCallToAction;
