import React, { useRef, useState } from "react";
import classes from "./Footer.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Overlay, Tooltip } from "react-bootstrap";
import Container from "react-bootstrap/Container";

const Footer = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <Container className={"footer-container"}>
      <Row className={"d-flex justify-content-between m-0 w-100"}>
        <Col xs={12} className={"col-md p-0"}>
          <p className={classes.copyrightText + " text-center text-lg-left"}>
            @2021 IHG. All rights reserved. Most hotels are Independently owned
            and operated.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
