import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatepickerInput from "./DatepickerInput";
import classes from "./DatepickerInput.module.css";

interface RangeDatePickerProps {
  currValue: any;
  updateValue: Function;
  onClick?: () => void;
  onChange?: () => void;
  onKeyDown?: () => void;
}

const RangeDatePicker: React.FC<RangeDatePickerProps> = (props) => {
  const {
    currValue,
    updateValue,
    onClick,
    onChange,
    onKeyDown,
    ...rest
  } = props;

  const onRangeChangeHandler = (dates: [Date, Date | null]) => {
    if (dates) {
      const [rangeStarts, rangeEnds] = dates;
      updateValue({ startDate: rangeStarts, endDate: rangeEnds });
    } else {
      updateValue({ startDate: null, endDate: null });
    }
  };

  const highlightWithRanges = () => {
    if (currValue.startDate && currValue.endDate) {
      if (
        moment(currValue.endDate).diff(moment(currValue.startDate), "days") > 0
      ) {
        return [
          {
            "react-datepicker__day--highlighted-custom": new Array(
              moment(currValue.endDate).diff(
                moment(currValue.startDate),
                "days"
              ) - 1
            )
              .fill(0)
              .map(
                (i, inx) =>
                  (i = moment(currValue.startDate)
                    .add(inx + 1, "days")
                    .toDate())
              ),
          },
        ];
      }
    }
    return [];
  };

  return (
    <div className={classes.datepickerWrapper}>
      <DatePicker
        selected={currValue.startDate}
        startDate={currValue.startDate}
        endDate={currValue.endDate}
        selectsRange
        isClearable
        minDate={moment().add(4, "days").toDate()}
        maxDate={moment().add(2, "years").toDate()}
        dayClassName={(date) => {
          const currDate = moment(date).format("YYYY MM DD"),
            rangeStart = moment(currValue.startDate).format("YYYY MM DD"),
            rangeEnd = moment(currValue.endDate).format("YYYY MM DD"),
            sameDayFuture = rangeStart.endsWith(currDate.substr(8, 2));

          return (
            (currDate === rangeStart || currDate === rangeEnd
              ? "range__edge--highlight"
              : sameDayFuture
              ? "white-bg"
              : "") + " border__radius--custom"
          );
        }}
        highlightDates={highlightWithRanges()}
        onChange={(dates: any) => onRangeChangeHandler(dates)}
        customInput={
          <DatepickerInput
            startDate={currValue.startDate}
            endDate={currValue.endDate}
            onClick={onClick}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        }
        showDisabledMonthNavigation
        shouldCloseOnSelect={false}
        popperClassName="datepicker__popper--custom"
        {...rest}
      />
    </div>
  );
};

export default RangeDatePicker;
