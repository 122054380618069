import React, { useState } from "react";
import classes from "./Search.module.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import RangeDatePicker from "../RangeDatePicker/RangeDatePicker";
import GooglePlaceInput from "./GooglePlaceInput";
import moment from "moment";

interface Props {
  destSelection: any;
  setDestSelection: any;
  options: any;
  updateDestSelection: Function;
  setDestination: Function;
  destination: any;
}

const Search = (props: Props) => {
  const [rooms, setRooms] = useState<string>();
  const [attendees, setAttendees] = useState<string>();
  const [datesRange, setDatesRange] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    let queryParams = {},
      queryURL = `https://${process.env.REACT_APP_CURRENT_ENVIRONMENT}/guest-events?`;

    // if (destination && destination.google_places_id)
    //   destination.forEach((dest: any, index: any = 0) => {
    //     queryParams = { location_id: dest[index].google_places_id };
    //   });
    if (props.destination) {
      let locationID = props.options.find((x: any) =>
        props.destination.includes(x.value)
      );
      queryParams = { location_id: locationID.google_places_id };

      queryParams = {
        ...queryParams,
        arrival_date:
          datesRange.startDate && moment(datesRange.startDate).isValid()
            ? moment(datesRange.startDate).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        departure_date:
          datesRange.endDate && moment(datesRange.endDate).isValid()
            ? moment(datesRange.endDate).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        attendee_count: attendees || 0,
        room_count: rooms || 0,
      };

      for (const [param, paramValue] of Object.entries(queryParams)) {
        queryURL = `${queryURL}${param}=${(paramValue as any).toString()}&`;
      }
      window.location.href =
        queryURL + "tenant_config_id=cfc44b5f-d1c3-4ff2-aa77-a0a2581991d8";
    }
    //} else setLocationError(true);
  };

  return (
    <div className={"w-100 border-bottom stickyTop"}>
      <div
        className={
          classes.searchContainer +
          " d-flex justify-content-center px-3 pt-5 pb-5"
        }
      >
        <Form
          onSubmit={onSubmitHandler}
          className={"d-flex flex-wrap justify-content-sm-center"}
        >
          <Form.Group controlId="formGroupDestination" className={"px-2"}>
            <Form.Label>
              Destination *{/* <span className={"text-primary"}>*</span>*/}
            </Form.Label>
            <GooglePlaceInput
              destSelection={props.destSelection}
              setCurrValue={props.setDestSelection}
              updateValue={(newValue: object) => {
                props.setDestination(newValue);
              }}
              options={props.options}
              updateDestSelection={props.updateDestSelection}
            />
          </Form.Group>
          <Form.Group controlId="formGroupDates" className={"px-2"}>
            <Form.Label>Dates</Form.Label>
            <RangeDatePicker
              currValue={datesRange}
              updateValue={(newValue: any) => {
                setDatesRange(newValue);
              }}
            />
          </Form.Group>
          <Form.Group controlId="formGroupRooms" className={"px-2"}>
            <Form.Label>Guest Rooms</Form.Label>
            <Form.Control
              type="number"
              placeholder="Rooms"
              size="lg"
              className={"hoverPrimary regularText minWidth2X"}
              value={rooms}
              min="1"
              onChange={(e) => {
                setRooms(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="formGroupEventSpace" className={"px-2"}>
            <Form.Label>Event Space</Form.Label>
            <Form.Control
              type="number"
              placeholder="Attendees"
              size="lg"
              min="1"
              className={"hoverPrimary regularText minWidth2X"}
              value={attendees}
              onChange={(e) => {
                setAttendees(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group
            controlId="formSearchBtn"
            className={"d-flex flex-column px-2"}
          >
            <Form.Label className={classes.disabledField + " noSelect"}>
              Search
            </Form.Label>
            <Button
              id="search-btn"
              type={"submit"}
              size={"lg"}
              className={"regularText btn-accent" + classes.lg__padding}
            >
              Search
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default Search;
