import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

interface Props {
  setDestSelection: any;
  options: any;
  updateDestSelection: Function;
  updateValue: Function;
  setDestination: any;
}

const SecondCallToAction = (props: Props) => {
  const { updateDestSelection } = props;

  return (
    <div className={"city-table-container py-5"}>
      <form>
        <Row className={"city-table-top-row"}>
          <Col xs={2} className={"pt-5 pb-3 text-md-left"}>
            <h1 className="city-table-header text-md-center">Choose a City</h1>
          </Col>
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className="text-md-center city-table-cell-header">
              California
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Concord");
                  updateDestSelection("Concord");
                }}
              >
                Concord
              </li>
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Los Angeles");
                  updateDestSelection("Los Angeles");
                }}
              >
                Los Angeles
              </li>
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("San Francisco");
                  updateDestSelection("San Francisco");
                }}
              >
                San Francisco
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">
              Colorado
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Denver");
                  updateDestSelection("Denver");
                }}
              >
                Denver
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">Florida</h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Lake Buena Vista");
                  updateDestSelection("Lake Buena Vista");
                }}
              >
                Lake Buena Vista
              </li>
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Miami");
                  updateDestSelection("Miami");
                }}
              >
                Miami
              </li>
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Orlando");
                  updateDestSelection("Orlando");
                }}
              >
                Orlando
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">
              Illinois
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Chicago");
                  updateDestSelection("Chicago");
                }}
              >
                Chicago
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3  city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">
              Louisiana
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("New Orleans");
                  updateDestSelection("New Orleans");
                }}
              >
                New Orleans
              </li>
            </ul>
          </Col>
        </Row>
        <Row className={"city-table-mid-row"}>
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className="text-md-center city-table-cell-header">Maryland</h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Baltimore");
                  updateDestSelection("Baltimore");
                }}
              >
                Baltimore
              </li>
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Gaithersburg");
                  updateDestSelection("Gaithersburg");
                }}
              >
                Gaithersburg
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">
              Michigan
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Grand Rapids");
                  updateDestSelection("Grand Rapids");
                }}
              >
                Grand Rapids
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">
              Minnesota
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Minnetonka");
                  updateDestSelection("Minnetonka");
                }}
              >
                Minnetonka
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">
              New York
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("New York");
                  updateDestSelection("New York");
                }}
              >
                New York City
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3  city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">
              N. Carolina
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Asheville");
                  updateDestSelection("Asheville");
                }}
              >
                Asheville
              </li>
            </ul>
          </Col>
          <Col xs={2} className={"pt-5 pb-3  city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">Ohio</h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Canton");
                  updateDestSelection("Canton");
                }}
              >
                Canton
              </li>
            </ul>
          </Col>
        </Row>
        <Row className={"city-table-bottom-row"}>
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className="text-md-center city-table-cell-header">
              Pennsylvania
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Philadelphia");
                  updateDestSelection("Philadelphia");
                }}
              >
                Philadelphia
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className="text-md-center city-table-cell-header">Tennessee</h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Nashville");
                  updateDestSelection("Nashville");
                }}
              >
                Nashville
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">Texas</h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("El Paso");
                  updateDestSelection("El Paso");
                }}
              >
                El Paso
              </li>
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Houston");
                  updateDestSelection("Houston");
                }}
              >
                Houston
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">
              Virginia
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Arlington");
                  updateDestSelection("Arlington");
                }}
              >
                Arlington
              </li>
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Dulles");
                  updateDestSelection("Dulles");
                }}
              >
                Dulles
              </li>

              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("McLean");
                  updateDestSelection("McLean");
                }}
              >
                McLean
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3 city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">
              Washington
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Seattle");
                  updateDestSelection("Seattle");
                }}
              >
                Seattle
              </li>
            </ul>
          </Col>{" "}
          <Col xs={2} className={"pt-5 pb-3  city-table-cell"}>
            <h5 className=" text-md-center city-table-cell-header ">
              Wisconsin
            </h5>
            <ul className="city-table-list text-md-center">
              <li
                className="destination-li"
                onClick={() => {
                  props.updateValue("Stevens Point");
                  updateDestSelection("Stevens Point");
                }}
              >
                Stevens Point
              </li>
            </ul>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default SecondCallToAction;
