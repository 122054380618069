import React from "react";
import MainMenu from "./components/Navigation/MainMenu";
import Footer from "./components/Footer/Footer";
import Search from "./components/Search/Search";
import FirstCallToAction from "./components/CallToActions/FirstCallToAction";
import SecondCallToAction from "./components/CallToActions/SecondCallToAction";
import ThirdCallToAction from "./components/CallToActions/ThirdCallToAction";
import "./App.scss";

function App() {
  const [destSelection, setDestSelection] = React.useState<any>([]);
  const [destination, setDestination] = React.useState<any>();

  console.log("destSelection in APP top level :>> ", destSelection);

  const updateDestSelection = (destItem: string) => {
    let destArr: Array<string> = [...destSelection];
    destArr.unshift(destItem);

    if (destItem === undefined) {
      setDestSelection([]);
      destArr = [];
      return destArr;
    } else setDestSelection(destArr);
    return destArr;
  };

  const options = [
    {
      value: "Concord",
      label: "Concord, CA",
      name: "Concord",
      state_code: "CA",
      destination_name: "Concord",
      google_places_id: "ChIJhczEaaxghYAR7_8flgRgQQ4",
      market_name: "Oakland, CA",
      market_id: "a4b05c1d-33a6-480b-bd55-331f251741ea",
    },
    {
      value: "Los Angeles",
      label: "Los Angeles, CA",
      name: "Los Angeles",
      state_code: "CA",
      destination_name: "Los Angeles",
      google_places_id: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
      market_name: "Los Angeles, CA",
      market_id: "fe48d109-0d08-42ab-bd4f-aeba962d460d",
    },
    {
      value: "San Francisco",
      label: "San Francisco, CA",
      name: "San Francisco",
      state_code: "CA",
      destination_name: "San Francisco",
      google_places_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
      market_name: "San Francisco/San Mateo, CA",
      market_id: "a9aed34a-05e5-48be-bc9d-7677bbd8cd78",
    },
    {
      value: "Denver",
      label: "Denver, CO",
      name: "Denver",
      state_code: "CO",
      destination_name: "Denver",
      google_places_id: "ChIJzxcfI6qAa4cR1jaKJ_j0jhE",
      market_name: "Denver, CO",
      market_id: "278b604c-4eb3-4d85-9f5e-175b2a30b7ed",
    },
    {
      value: "Lake Buena Vista",
      label: "Lake Buena Vista, FL",
      name: "Lake Buena Vista",
      state_code: "FL",
      destination_name: "Lake Buena Vista",
      google_places_id: "ChIJCadk6PF_3YgRzKY88sQkblg",
      market_name: "Orlando, FL",
      market_id: "a94c5c8f-59e1-419c-83a1-3092bbc373df",
    },
    {
      value: "Miami",
      label: "Miami, FL",
      name: "Miami",
      state_code: "FL",
      destination_name: "Miami",
      google_places_id: "ChIJEcHIDqKw2YgRZU-t3XHylv8",
      market_name: "Miami, FL",
      market_id: "2a049ed3-b2a6-40b4-bfd8-9c835984918e",
    },
    {
      value: "Orlando",
      label: "Orlando, FL",
      name: "Orlando",
      state_code: "FL",
      destination_name: "Orlando",
      google_places_id: "ChIJd7zN_thz54gRnr-lPAaywwo",
      market_name: "Orlando, FL",
      market_id: "a94c5c8f-59e1-419c-83a1-3092bbc373df",
    },
    {
      value: "Chicago",
      label: "Chicago, IL",
      name: "Chicago",
      state_code: "IL",
      destination_name: "Chicago",
      google_places_id: "ChIJ7cv00DwsDogRAMDACa2m4K8",
      market_name: "Chicago, IL",
      market_id: "202837b8-8a01-430e-9265-815c20e9e5ac",
    },

    {
      value: "New Orleans",
      label: "New Orleans, LA",
      name: "New Orleans",
      state_code: "LA",
      destination_name: "New Orleans",
      google_places_id: "ChIJZYIRslSkIIYRtNMiXuhbBts",
      market_name: "New Orleans, LA",
      market_id: "2a38cc6a-ba6a-46cf-bef8-8efd8ae84806",
    },
    {
      value: "Baltimore",
      label: "Baltimore, MD",
      name: "Baltimore",
      state_code: "MD",
      destination_name: "Baltimore",
      google_places_id: "ChIJt4P01q4DyIkRWOcjQqiWSAQ",
      market_name: "Baltimore, MD",
      market_id: "70ed9bf9-b278-48aa-9dc4-ca994fa0f017",
    },
    {
      value: "Gaithersburg",
      label: "Gaithersburg, MD",
      name: "Gaithersburg",
      state_code: "MD",
      destination_name: "Gaithersburg",
      google_places_id: "ChIJtRPKJDYttokRNXKtgIFaj-M",
      market_name: "Washington, DC",
      market_id: "54dd1c1e-7673-48c4-936e-45cb0b60a2e7",
    },
    {
      value: "Grand Rapids",
      label: "Grand Rapids, MI",
      name: "Grand Rapids",
      state_code: "MI",
      destination_name: "Grand Rapids",
      google_places_id: "ChIJFShQu2BUGIgR0KjTG8uqk6U",
      market_name: "Grand Rapids, MI",
      market_id: "cf45faaa-3ec5-4088-a399-3932261f07f4",
    },
    {
      value: "Minnetonka",
      label: "Minnetonka, MN",
      name: "Minnetonka",
      state_code: "MN",
      destination_name: "Minnetonka",
      google_places_id: "ChIJUQgaEjMe9ocRH1c_CV3xBno",
      market_name: "Minneapolis/St Paul, MN",
      market_id: "821b8d5e-2d0d-42e2-b20f-00aa67076767",
    },
    {
      value: "New York",
      label: "New York, NY",
      name: "New York",
      state_code: "NY",
      destination_name: "New York",
      google_places_id: "ChIJOwg_06VPwokRYv534QaPC8g",
      market_name: "New York, NY",
      market_id: "f725eb09-2a74-4e66-a38b-ee29931409f3",
    },
    {
      value: "Asheville",
      label: "Ashville, NC",
      name: "Asheville",
      state_code: "NC",
      destination_name: "Asheville",
      google_places_id: "ChIJCW8PPKmMWYgRXTo0BsEx75Q",
      market_name: "Asheville, NC",
      market_id: "a6caf7d0-3c91-424c-815f-42e1c8cc45bc",
    },
    {
      value: "Canton",
      label: "Canton, OH",
      name: "Canton",
      state_code: "OH",
    },
    {
      value: "Philadelphia",
      label: "Philadelphia, PA",
      name: "Philadelphia",
      state_code: "PA",
      destination_name: "Philadelphia",
      google_places_id: "ChIJ60u11Ni3xokRwVg-jNgU9Yk",
      market_name: "Philadelphia, PA",
      market_id: "9f1d098b-dcc3-4f71-983f-6b1eb4bd50c1",
    },
    {
      value: "Nashville",
      label: "Nashville, TN",
      name: "Nashville",
      state_code: "TN",
      destination_name: "Nashville",
      google_places_id: "ChIJPZDrEzLsZIgRoNrpodC5P30",
      market_name: "Nashville, TN",
      market_id: "a69beb3e-7991-4224-8d6d-303c094f87d9",
    },
    {
      value: "El Paso",
      label: "El Paso, TX",
      name: "El Paso",
      state_code: "TX",
      destination_name: "El Paso",
      google_places_id: "ChIJaTv-xYs_54YRIgKdq-OEkeM",
      market_name: "El Paso, TX",
      market_id: "1de3cf9b-1ff9-4c9f-b879-50e77bf7e9fb",
    },
    {
      value: "Houston",
      label: "Houston, TX",
      name: "Houston",
      state_code: "TX",
      destination_name: "Houston",
      google_places_id: "ChIJAYWNSLS4QIYROwVl894CDco",
      market_name: "Houston, TX",
      market_id: "12f9e84b-de14-4814-b91f-81f1e7aa58f1",
    },
    {
      value: "Arlington",
      label: "Arlington, VA",
      name: "Arlington",
      state_code: "VA",
      destination_name: "Arlington",
      google_places_id: "ChIJD6ene522t4kRk7D2Rchvz_g",
      market_name: "Washington, DC",
      market_id: "54dd1c1e-7673-48c4-936e-45cb0b60a2e7",
    },
    {
      value: "Dulles",
      label: "Dulles, VA",
      name: "Dulles",
      state_code: "VA",
      destination_name: "Dulles",
      google_places_id: "ChIJuc_EVAw2tokRvPJtxs0zbpI",
      market_name: "Washington, DC",
      market_id: "54dd1c1e-7673-48c4-936e-45cb0b60a2e7",
    },
    {
      value: "McLean",
      label: "McLean, VA",
      name: "McLean",
      state_code: "VA",
      destination_name: "McLean",
      google_places_id: "ChIJO3mKsew1tokR8er6rV66Yo4",
      market_name: "Washington, DC",
      market_id: "54dd1c1e-7673-48c4-936e-45cb0b60a2e7",
    },
    {
      value: "Seattle",
      label: "Seattle, WA",
      name: "Seattle",
      state_code: "WA",
      destination_name: "Seattle",
      google_places_id: "ChIJVTPokywQkFQRmtVEaUZlJRA",
      market_name: "Seattle, WA",
      market_id: "04e74f91-f620-4d23-943d-d5e5bdc68cd9",
    },
    {
      value: "Stevens Point",
      label: "Stevens Point, WI",
      name: "Stevens Point",
      state_code: "WI",
      destination_name: "Stevens Point",
      google_places_id: "ChIJP5gzjY1aAIgRM67SsGh5voI",
      market_name: "Wausau/Stevens Point, WI",
      market_id: "5a33db5e-3f20-4e99-82b6-0ebb1138123f",
    },
  ];

  return (
    <>
      <header className="App-header">
        <MainMenu />
        <div className={"headerImage w-100"}>
          <div className={"small-events-text-header"}>
            <h1>Small events made easier.</h1>
            <h5>
              Instantly book your group, meeting or event in one
              <br></br>
              of our featured destinations.
            </h5>
          </div>
          <div className="title-bg-img">
            <h5>InterContinental New York Barclay</h5>
          </div>
        </div>
      </header>
      <main>
        <Search
          destSelection={destSelection}
          options={options}
          setDestSelection={setDestSelection}
          updateDestSelection={updateDestSelection}
          setDestination={setDestination}
          destination={destination}
        />
        <FirstCallToAction />
        <SecondCallToAction
          setDestSelection={setDestSelection}
          options={options}
          updateDestSelection={updateDestSelection}
          setDestination={setDestination}
          updateValue={(newValue: object) => {
            setDestination(newValue);
          }}
        />
        <ThirdCallToAction />
        <Footer />
      </main>
    </>
  );
}

export default App;
